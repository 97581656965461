import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PostResumeSample } from '@talentinc/gatsby-theme-ecom/types/post'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import BasePostTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/Post'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import { ScrollProgressProvider } from '@talentinc/gatsby-theme-ecom/components/Post/ScrollProgress'

import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import NavTestHeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/NavTestHeaderV2'

const PostResumeSampleTemplate: React.FC<
  PageProps<{ postResumeSample: PostResumeSample } & PageDataContextType>
> = (props) => {
  const { postResumeSample, ...pageData } = props.data

  return (
    <Layout pageTitle={postResumeSample.title} pageData={pageData}>
      {postResumeSample.seo && (
        <SEO {...postResumeSample.seo} post={postResumeSample} />
      )}
      <ScrollProgressProvider>
        <HeaderV2 showBlogNavigation />
        <NavTestHeaderV2 showBlogNavigation />
        <BasePostTemplate post={postResumeSample} />
      </ScrollProgressProvider>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query PostResumeSamplesBySlugAndBrand($slug: String!, $brandName: String!) {
    postResumeSample: contentfulPostResumeSample(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...PostResumeSample
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "3Nd6Keg87eW5pGryLZS2ZA" }
    ) {
      ...HeaderV2
    }
    navTestHeaderV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "6yZRtzPjZxKCylBbpXF0oH" }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PostResumeSampleTemplate
